import React, { useState, useEffect } from 'react';
import DeliveryMap from './DeliveryMap';
import Navbar from './Navbar'
import './tracking.css';


import { formatStatusFields, formatETA, formatCreatedAt, formatSnakeCase, MapLoading } from './utils';
import { DeliveryTrackingBox, DeliveryProof, DeliveryTimeline } from './DeliveryTrackingBoxes'

import { Wrapper } from '@googlemaps/react-wrapper';


function formatSnakeCases(snakeCaseString) {
  return snakeCaseString
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

const DeliveryTrackingPage = () => {
    const [jobId, setJobId] = useState(new URLSearchParams(window.location.search).get('jobId'));
    const [valid, setValid] = useState(false);
	const [orderId, setOrderId] = useState('');
 const [isOpen, setIsOpen] = useState(false);

  // Function to open the modal
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };
 const [isToggled, setIsToggled] = useState([false, false]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    // Function to check screen size
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    // Add event listener to track screen size changes
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggle = (index) => {
    setIsToggled((prev) =>
      prev.map((item, i) => (i === index ? !item : item))
    );
  };
    // Stores pickup and dropoff locations (set once)
    // Converting addresses costs money, so we only want it to happen once
    const [deliveryData, setDeliveryData] = useState({
        pickupAddress: '',
        dropoffAddress: '',
    });

    // Stores current delivery status (changes)
    const [deliveryStatus, setDeliveryStatus] = useState({
        driver: '',
        driverLocation: '',
        driverStatus: '',
        driverNumber: '',
        eta: '',
    });

    // Stores current delivery step
    const [deliveryStep, setDeliveryStep] = useState({
        assignedDriver: '12:00pm',
        pickupEnroute: '12:34pm',
        pickupComplete: '-',
        dropoffEnroute: '-',
        dropoffArrived: '-',
        dropoffCompleted: '-'
    });

    const updateDeliveryStatus = async (first = false) => {
        let result;

        try {
            const response = await fetch(process.env.REACT_APP_DELIVERY_STATUS_ENDPOINT + jobId);
            setValid(response.status === 200);
            result = await response.json();
        } catch (err) {
            console.log(err);
            setValid(false);
        }

        if (first) {
            setDeliveryData({
                pickupAddress: result.pickupAddress,
                dropoffAddress: result.dropoffAddress,
            });
        }
        setOrderId(result.orderId);

        result = formatStatusFields(result);

        setDeliveryStatus({
            driverName: result.courierName,
            driverLocation: result.driverLocation,
            driverStatus: result.status,
            driverNumber: result.courierPhoneNumber,
            eta: result.dropoffEta,
            statusHistory: result.statusHistory,
            proofOfDelivery: result.proofOfDelivery,
        });
    }

    useEffect(() => {
        // Get initial delivery data
        updateDeliveryStatus(true);

        // Get additional update every 30 seconds
        const interval = setInterval(() => {
            updateDeliveryStatus();
        }, 3000);

        // This will be run when the component is destroyed
        return () => clearInterval(interval);
    }, []);

    if (!valid)
        return <p>Loading</p>

    return (
       <div id='deliverytracking-wrapper'>
            <Navbar />
			 <div className='deliverytracking-main'>
				<div className='deliverytracking-sec'>
					<div className='deliverytracking-left timeline-left'>
							{isMobile ? ( 
							<>
							<button onClick={() => toggle(0)} className="delivery-toogle" id={isToggled[0] ? 'removeminus' : 'removemplus'}> 
								<div className="delivery-timeline-top">
								<div className="delivery-timeline-status">
									<DeliveryTrackingBox
										elements={[
											{ label: '', value: formatSnakeCases(deliveryStatus.driverStatus) },
										]}
									/>
								</div>
								 <div className="delivery-timeline-order"><div>Order Number:</div>{orderId}</div>
								</div>
								<div className="delivery-timeline-arrow"> {isToggled[0] ? <span><svg width="22" height="68" viewBox="0 0 22 68" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 29L10.2929 38.2929C10.6834 38.6834 11.3166 38.6834 11.7071 38.2929L21 29" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
								</svg></span> : <svg width="22" height="68" viewBox="0 0 22 68" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 29L10.2929 38.2929C10.6834 38.6834 11.3166 38.6834 11.7071 38.2929L21 29" stroke="#686868" stroke-width="2" stroke-linecap="round"/>
								</svg>
								}</div>
							</button>
							{isToggled[0] && (
							<div className='delivery-timeline-container'>
								<DeliveryTimeline
									steps={deliveryStatus.statusHistory}
								/>
							</div>
							)}</>
							) : (
							<>
							<div className="delivery-timeline-top">
							<div className="delivery-timeline-status">
								<DeliveryTrackingBox
									elements={[
										{ label: '', value: formatSnakeCases(deliveryStatus.driverStatus) },
									]}
								/>
							</div>
							 <div className="delivery-timeline-order"><div>Order Number:</div>{orderId}</div>
							</div>
							<div className='delivery-timeline-container'>
								
								<DeliveryTimeline
									steps={deliveryStatus.statusHistory}
								/>
							</div>
							</>
							)}
					</div>
					<div className='deliverytracking-right map-border'>
						<div id='deliverytracking-map-wrapper'>
							<div id='deliverytracking-map'>
							<Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} render={MapLoading} libraries={['places, directions']}>
								<DeliveryMap className='deliverytracking-border'
									pickupAddress={deliveryData.pickupAddress}
									dropoffAddress={deliveryData.dropoffAddress}
									driverLocation={deliveryStatus.driverLocation}
								/>
							</Wrapper>
							</div>
							<div className='deliverytracking-map-deatils'>
								{isMobile ? ( 
										<>
										<button onClick={() => toggle(1)} className="driver-toogle" id={isToggled[1] ? 'removeplus' : 'removeminus'}>  {isToggled[1] ? <span>-</span> : 'View Driver Details'}</button>
										{isToggled[1] && (
											<DeliveryTrackingBox
											elements={[
											{ label: 'Driver: ', value: deliveryStatus.driverName || 'Not Yet Assigned' },
											{ label: 'Number: ', value: deliveryStatus.driverNumber || 'Not Yet Assigned' },
											{ label: 'ETA: ', value: formatETA(deliveryStatus.eta) },
											]}
											/>
										)}</>
										) : (
											<DeliveryTrackingBox
											elements={[
											{ label: 'Driver: ', value: deliveryStatus.driverName || 'Not Yet Assigned' },
											{ label: 'Number: ', value: deliveryStatus.driverNumber || 'Not Yet Assigned' },
											{ label: 'ETA: ', value: formatETA(deliveryStatus.eta) },
											]}
											/>
										)}
							</div>
						</div>
					</div>
				</div>
				<div className='deliverytracking-sec'>
					<div className='deliverytracking-left'>
						<div className='Delivery-Pickup'>
							
							<DeliveryTrackingBox
							elements={[
							{ label: 'Pickup: ', value: deliveryData.pickupAddress },
							{ label: 'Dropoff: ', value: deliveryData.dropoffAddress },
							]}
							/>
						</div>
					</div>
                    {deliveryStatus.proofOfDelivery && (
                        <div className='deliverytracking-right proofOfdelivery-sec'>
                            <div id="deliveryProof-info">
							 <div>
								 <button onClick={openModal} style={{ cursor: 'pointer', maxWidth: '230px' }} > <DeliveryProof proof={deliveryStatus.proofOfDelivery} /></button>
									{isOpen && (
										<div className="modal-overlay" onClick={closeModal}>
									  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
										<span className="close-button" onClick={closeModal}>
										  &times;
										</span>
										<DeliveryProof proof={deliveryStatus.proofOfDelivery} />
									  </div>
									</div>
								  )}
								</div>
                            
                            </div>
							
                        </div>
                    )}
			   </div>
          </div>  
           
            {/* <div id="deliverytracking-page-right-side"> */}
            {/* <DeliveryTrackingStep title="Assigned Driver" time={deliveryStep.assignedDriver} />
                    <DeliveryTrackingStep title="Pickup Enroute" time={deliveryStep.pickupEnroute} />
                    <DeliveryTrackingStep title="Pickup Complete" time={deliveryStep.pickupComplete} />
                    <DeliveryTrackingStep title="Dropoff Enroute" time={deliveryStep.dropoffEnroute} />
                    <DeliveryTrackingStep title="Dropoff Arrived" time={deliveryStep.dropoffArrived} />
                    <DeliveryTrackingStep title="Dropoff Complete" time={deliveryStep.dropoffCompleted} /> */}
            {/* </div> */}
        </div>
    );
}

export default DeliveryTrackingPage